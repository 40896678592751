import Frog from '@yuanfudao/frog'
import { platform, query as _query, ua as _ua } from '@yuanfudao/ccgp-common-util'

window.localStorage && window.localStorage.removeItem('_fr_g_')
const query = _query.parse()

const appendCommonKV = (keyValues) => {
  keyValues.push({ key: 'refUrl', value: window.location.href })
  keyValues.push({ key: 'userAgent', value: window.navigator.userAgent })
  keyValues.push({ key: 'SharePlatform', value: platform.getPlatformId() })
  if (query.workShareFrom) {
    keyValues.push({ key: 'workShareFrom', value: query.workShareFrom })
  }

  if (query.conanFrom) {
    keyValues.push({ key: 'conanFrom', value: query.conanFrom })
  }

  if (query.from) {
    keyValues.push({ key: 'from', value: query.from })
  }
  if (query.web_keyfrom) {
    keyValues.push({ key: 'keyfrom', value: query.web_keyfrom })
  }
  if (query.channeltype) {
    keyValues.push({ key: 'channeltype', value: query.channeltype })
  }
  if (query.pageName) {
    keyValues.push({ key: 'page', value: query.pageName })
  }
  if (query.wx_aid) {
    keyValues.push({ key: 'aid', value: query.wx_aid })
  }

  if (_ua.isConanClient()) {
    keyValues.push({ key: 'hostName', value: 'Zebra' })
  } else if (_ua.isWechat()) {
    keyValues.push({ key: 'hostName', value: 'Wechat' })
  } else {
    keyValues.push({ key: 'hostName', value: 'other' })
  }
  return keyValues
}

const install = (Vue) => {
  Vue.prototype.$frog = function (config) {
    config = config || {}
    config.keyValues = config.keyValues || []
    appendCommonKV(config.keyValues)
    return new Frog(config)
  }

  Vue.prototype.$addFrog = function (url, keyValues, productId = 533) {
    keyValues = keyValues || []
    appendCommonKV(keyValues)
    const ins = new Frog({ productId: productId, keyValues })
    ins.add(url)
    ins.send()
  }

  Vue.prototype.$addFrogList = function (productId, urlList, keyValues) {
    keyValues = keyValues || []
    appendCommonKV(keyValues)
    const ins = new Frog({ productId: productId, keyValues })
    ins.add.apply(ins, urlList)
    ins.send()
  }
}

export default { install }
