<template>
  <div class="product-desc">
    <p class="title">{{ productInfo.title }}</p>
    <p class="desc">
      {{ productInfo.subTitle }}
    </p>

    <div class="product-card">
      <div class="left-box">
        <img class="left" :src="leftImageConfig.pcBgImage | img2WebpFilter" />
        <div @click="jumpPage(leftImageConfig)" class="click-btn"></div>
      </div>
      <div class="right">
        <div v-for="(img, index) in rightImageConfigList" :key="index" class="right-box">
          <img class="right-img" :src="img.pcBgImage | img2WebpFilter" />
          <div @click="jumpPage(img)" class="click-btn"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    productInfo: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    leftImageConfig() {
      const theme = Object.keys(this.productInfo.themeBgConfig)[0]
      return {
        ...this.productInfo.themeBgConfig[theme],
        tag: theme
      }
    },
    rightImageConfigList() {
      const themeList = Object.keys(this.productInfo.themeBgConfig).slice(1)
      return themeList.map((theme) => {
        return {
          ...this.productInfo.themeBgConfig[theme],
          tag: theme
        }
      })
    }
  },
  methods: {
    jumpPage(config) {
      if (config.jumpUrl) {
        this.sendEvent('/click/ZebraAIWebsite/subpage', 2, config.tag)
        window.location.href = config.jumpUrl
      }
    },
    sendEvent(path, position, pageType) {
      const commonParams = [
        { key: 'devicetype', value: 'pc' },
        { key: 'subpage', value: pageType },
        { key: 'position', value: position },
        { key: 'pageType', value: 'home' }
      ]
      this.$addFrog(path, commonParams)
    }
  }
}
</script>
<style scoped lang="less">
.product-desc {
  text-align: center;
}
.title {
  margin-top: 80px;
  font-weight: 600;
  font-size: 40px;
  line-height: 56px;
  color: #000;
}
.desc {
  width: 936px;
  margin: 24px auto 0;
  font-size: 16px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.6);
}
.product-card {
  display: flex;
  justify-content: center;
  margin-top: 64px;
  img {
    border-radius: 8px;
  }
  .click-btn {
    position: absolute;
    height: 124px;
    width: 210px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
  }
}
.left-box {
  position: relative;
  font-size: 0;
}
.left {
  width: 552px;
  height: 728px;
}
.right {
  display: flex;
  flex-wrap: wrap;
  width: 580px;
  .right-box {
    position: relative;
    font-size: 0;
  }
  .right-img {
    width: 264px;
    height: 352px;
    margin-left: 24px;
  }
  .right-box:nth-child(1) {
    margin-bottom: 24px;
  }
  .right-box:nth-child(2) {
    margin-bottom: 24px;
  }
}
</style>
