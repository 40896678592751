import { render, staticRenderFns } from "./PBanner.vue.js?vue&type=template&id=0cd4defe&scoped=true!=!../../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@2.0.1_webpack@5.97.0__css-loader@7.1.2_@rspack+core@1.1.5_@sw_jnagt6goqnkpekpgaetqdid6du/node_modules/vue-loader/lib/index.js??vue-loader-options!./PBanner.vue?vue&type=template&id=0cd4defe&scoped=true"
import script from "./PBanner.vue.js?vue&type=script&lang=js!=!../../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@2.0.1_webpack@5.97.0__css-loader@7.1.2_@rspack+core@1.1.5_@sw_jnagt6goqnkpekpgaetqdid6du/node_modules/vue-loader/lib/index.js??vue-loader-options!./PBanner.vue?vue&type=script&lang=js"
export * from "./PBanner.vue.js?vue&type=script&lang=js!=!../../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@2.0.1_webpack@5.97.0__css-loader@7.1.2_@rspack+core@1.1.5_@sw_jnagt6goqnkpekpgaetqdid6du/node_modules/vue-loader/lib/index.js??vue-loader-options!./PBanner.vue?vue&type=script&lang=js"
import style0 from "./PBanner.vue.less?vue&type=style&index=0&id=0cd4defe&prod&lang=less&scoped=true!=!../../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@2.0.1_webpack@5.97.0__css-loader@7.1.2_@rspack+core@1.1.5_@sw_jnagt6goqnkpekpgaetqdid6du/node_modules/vue-loader/lib/index.js??vue-loader-options!./PBanner.vue?vue&type=style&index=0&id=0cd4defe&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@2.0.1_webpack@5.97.0__css-loader@7.1.2_@rspack+core@1.1.5_@sw_jnagt6goqnkpekpgaetqdid6du/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cd4defe",
  null
  
)

export default component.exports