<template>
  <img
    v-if="showBar"
    @click="onButtonClick"
    class="download-bar"
    src="https://conan-online.fbcontent.cn/conan-oss-resource/dc72gl1gw.png"
  />
</template>
<script>
import tool from '@/utils/index'
import { ua as _ua } from '@yuanfudao/ccgp-common-util'

export default {
  props: {
    frogPath: {
      type: String,
      default: ''
    },
    pageType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showBar: !_ua.isConanClient() && !_ua.isInWechatMP()
    }
  },
  mounted() {
    this.initScroll()
  },
  methods: {
    initScroll() {
      this.showBar &&
        window.addEventListener('scroll', () => {
          clearTimeout(this.timer)
          this.showBar = false
          this.timer = setTimeout(() => {
            this.showBar = true
          }, 100)
        })
    },
    onButtonClick() {
      this.sendEvent()
      location.href = tool.getDownloadAppUrl()
    },
    sendEvent() {
      const commonParams = [
        { key: 'devicetype', value: 'mobile' },
        { key: 'pageType', value: this.pageType },
        { key: 'position', value: 5 }
      ]
      this.$addFrog(this.frogPath, commonParams)
    }
  }
}
</script>

<style scoped lang="less">
.download-bar {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
}
</style>
