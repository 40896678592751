import { render, staticRenderFns } from "./MNewsCard.vue.js?vue&type=template&id=3dd1f510&scoped=true!=!../../../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@2.0.1_webpack@5.97.0__css-loader@7.1.2_@rspack+core@1.1.5_@sw_jnagt6goqnkpekpgaetqdid6du/node_modules/vue-loader/lib/index.js??vue-loader-options!./MNewsCard.vue?vue&type=template&id=3dd1f510&scoped=true"
import script from "./MNewsCard.vue.js?vue&type=script&lang=js!=!../../../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@2.0.1_webpack@5.97.0__css-loader@7.1.2_@rspack+core@1.1.5_@sw_jnagt6goqnkpekpgaetqdid6du/node_modules/vue-loader/lib/index.js??vue-loader-options!./MNewsCard.vue?vue&type=script&lang=js"
export * from "./MNewsCard.vue.js?vue&type=script&lang=js!=!../../../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@2.0.1_webpack@5.97.0__css-loader@7.1.2_@rspack+core@1.1.5_@sw_jnagt6goqnkpekpgaetqdid6du/node_modules/vue-loader/lib/index.js??vue-loader-options!./MNewsCard.vue?vue&type=script&lang=js"
import style0 from "./MNewsCard.vue.less?vue&type=style&index=0&id=3dd1f510&prod&scoped=true&lang=less!=!../../../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@2.0.1_webpack@5.97.0__css-loader@7.1.2_@rspack+core@1.1.5_@sw_jnagt6goqnkpekpgaetqdid6du/node_modules/vue-loader/lib/index.js??vue-loader-options!./MNewsCard.vue?vue&type=style&index=0&id=3dd1f510&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@2.0.1_webpack@5.97.0__css-loader@7.1.2_@rspack+core@1.1.5_@sw_jnagt6goqnkpekpgaetqdid6du/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dd1f510",
  null
  
)

export default component.exports