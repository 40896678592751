<template>
  <section class="error-container">
    <img
      v-if="loadStatus !== 'loading'"
      class="load-error"
      src="../../assets/images/load-error.png"
    />
  </section>
</template>
<script>
export default {
  props: {
    loadStatus: {
      type: String,
      default: 'loading'
    }
  }
}
</script>
<style scoped>
.error-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  text-align: center;
}
.load-error {
  margin-top: 100px;
  height: 200px;
  width: 200px;
}
</style>
