<template>
  <div class="news-and-report">
    <p class="title">媒体关注与报道</p>
    <div class="news-and-report-card">
      <div v-for="(newsItem, index) in newsList" :key="index" class="news-card">
        <MNewsCard
          :title="newsItem.title"
          :desc="newsItem.content"
          :imgUrl="newsItem.imgUrl"
          cardHeight="332px"
          imgHeight="124px"
        />
      </div>
    </div>
  </div>
</template>
<script>
import MNewsCard from './MNewsCard.vue'
export default {
  components: {
    MNewsCard
  },
  props: {
    mediaInfo: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    newsList() {
      return this.mediaInfo.newsList || []
    }
  }
}
</script>
<style scoped lang="less">
.news-and-report {
  margin-bottom: 60px;
}
.news-and-report-card {
  display: flex;
  overflow: scroll;
  margin: 0 auto;
}
.title {
  margin-top: 0.6rem;
  margin-bottom: 0.2rem;
  font-weight: 600;
  font-size: 0.2rem;
  line-height: 0.28rem;
  text-align: center;
  color: #000;
}
.news-card {
  padding-right: 0.16rem;
}
.news-card:first-child {
  margin-left: 0.16rem;
}
</style>
