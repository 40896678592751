import request from 'superagent'
import config from '../config'

let BASE_URL = `https:${config.CONAN_HOST}`
export default {
  getIpAddress() {
    const url = `https:${config.CONAN_HOST}/conan-growth-misc/api/client-info`
    return request.get(url).then((res) => res.body.ip)
  },

  getDownloadUrlNew(env) {
    let baseUrl = 'https://conan.yuanfudao.com'
    if (env === 'dev' || env === 'test') {
      baseUrl = 'https://conan.zhenguanyu.com'
    }
    const PenAssistantResourceType = [
      'englishCourseMaterial',
      'mathCourseMaterial',
      'chineseCourseMaterial',
      'outsideReading'
    ]
    return Promise.all(
      PenAssistantResourceType.map((type) =>
        request
          .get(`${baseUrl}/conan-zdt-pen/api/resources/package?type=${type}`)
          .then((res) => res.text)
          .catch(() => '')
      )
    )
  },

  fetchEncyclopediaConfigPC() {
    return request(
      `https:${config.CONAN_HOST}/conan-growth-misc/api/common-config/Space_Buff/zebraHomePageEcycV2PC`
    ).then((res) => {
      return res.body
    })
  },
  fetchEncyclopediaConfigMobile() {
    return request(
      `https:${config.CONAN_HOST}/conan-growth-misc/api/common-config/Space_Buff/zebraHomePageEcycV2Mobile`
    ).then((res) => {
      return res.body
    })
  },
  fetchIndexConfig() {
    return request(
      `${BASE_URL}/conan-growth-misc/api/common-config/Space_Buff/zebraHomePageIndex`
    ).then((res) => res.body)
  },
  fetchBookConfig() {
    return request(
      `https:${config.CONAN_HOST}/conan-growth-misc/api/common-config/Space_Buff/zebraHomePageBook`
    ).then((res) => {
      return res.body
    })
  },
  fetchToyConfig() {
    return request(
      `https:${config.CONAN_HOST}/conan-growth-misc/api/common-config/Space_Buff/zebraHomePageToy`
    ).then((res) => {
      return res.body
    })
  },
  fetchBanMaConfig() {
    return request(
      `${BASE_URL}/conan-growth-misc/api/common-config/Space_Buff/zebraHomePageBanma`
    ).then((res) => res.body)
  },
  fetchDownloadConfig() {
    return request(
      `${BASE_URL}/conan-growth-misc/api/common-config/Space_Buff/zebraHomePageDownload`
    ).then((res) => res.body)
  },
  fetchBanMaLessonInfo(courseIds = []) {
    return request(`${BASE_URL}/conan-growth-lesson/api/course/sale-info`)
      .query({ courseIds })
      .then((res) => res.body)
  },
  getDownloadCenterConfig() {
    return request
      .get(`https:${config.CONAN_HOST}/conan-commerce-marketing/api/official/banma-download-config`)
      .then((res) => {
        return JSON.parse(res.text)
      })
  }
}
