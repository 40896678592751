var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btn",class:{ skeleton: _vm.skeleton },style:({
    lineHeight: _vm.height,
    width: _vm.width,
    background: _vm.skeleton ? 'transparent' : _vm.background,
    color: _vm.skeleton ? '' : _vm.color,
    fontSize: _vm.fontSize
  }),on:{"click":_vm.handleClick}},[_vm._v(" "+_vm._s(_vm.text)+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }