<template>
  <div
    class="btn"
    :class="{ skeleton: skeleton }"
    :style="{
      lineHeight: height,
      width: width,
      background: skeleton ? 'transparent' : background,
      color: skeleton ? '' : color,
      fontSize: fontSize
    }"
    @click="handleClick"
  >
    {{ text }}
  </div>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    skeleton: {
      type: Boolean
    },
    height: {
      type: String,
      default: '56px'
    },
    width: {
      type: String,
      default: '280px'
    },
    background: {
      type: String,
      default: '#00CC66'
    },
    color: {
      type: String,
      default: '#FFF'
    },
    fontSize: {
      type: String,
      default: '20px'
    }
  },
  methods: {
    handleClick() {
      this.$emit('click')
    }
  }
}
</script>

<style scoped lang="less">
.btn {
  text-align: center;
  border-radius: 100px;
  font-weight: 600;
  cursor: pointer;
}
.skeleton {
  border: 1px solid #00cc66;
  color: #00cc66;
}
</style>
