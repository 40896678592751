<template>
  <div class="about-us-box">
    <div v-show="!isShowQrCode" class="about-us" @mouseenter="showQrCode">
      <img src="../../../assets/images/weixin.jpg" class="weixin-icon" />
      <div class="focus-notice">关注我们</div>
    </div>
    <div v-show="isShowQrCode" class="qrcode" @mouseleave="hideQrCode">
      <div class="title">扫描二维码</div>
      <div class="desc">关注斑马官方公众号关注更多活动</div>
      <img
        class="qrcode-img"
        src="https://conan-online.fbcontent.cn/conan-oss-resource/m0sr1e1FcK2ORv8vG4iTjX4hJUEGIdmbfaGJ.jpeg"
      />
    </div>
  </div>
</template>
<script>
import PQrCodeFloat from '../PQrCodeFloat.vue'
export default {
  components: {
    PQrCodeFloat
  },
  data() {
    return {
      isShowQrCode: false
    }
  },
  methods: {
    showQrCode() {
      this.isShowQrCode = true
      this.sendEvent()
    },
    hideQrCode() {
      this.isShowQrCode = false
    },
    sendEvent() {
      const commonParams = [
        { key: 'devicetype', value: 'pc' },
        { key: 'pageType', value: 'home' }
      ]
      this.$addFrog('/event/ZebraAIWebsite/gzhView', commonParams)
    }
  }
}
</script>
<style scoped lang="less">
.about-us-box {
  font-family: 'PingFang SC';

  .about-us {
    position: fixed;
    right: 0;
    top: 412px;
    z-index: 999;
    height: 108px;
    width: 96px;
    background: #ffffff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
    border-radius: 8px 0 0 8px;
    .weixin-icon {
      width: 48px;
      height: 48px;
      margin: 16px 24px 8px;
    }
    .focus-notice {
      text-align: center;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.6);
    }
  }
  .qrcode {
    position: fixed;
    right: 16px;
    top: 312px;
    width: 168px;
    padding: 16px 0;
    z-index: 999;
    box-sizing: border-box;
    border-radius: 8px;
    text-align: center;
    background: #fff;
    .title {
      padding: 0 24px;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #000;
    }
    .desc {
      padding: 0 24px;
      margin-top: 8px;
      font-size: 12px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.6);
    }
    .qrcode-img {
      height: 134px;
      width: 134px;
    }
  }
}
</style>
