<template>
  <!-- 移动端 banner, 纯 UI 组件 -->
  <section class="banner-container" :style="backgroundStyle">
    <div class="banner-cover-image">
      <img v-if="coverImageUrl" alt="" :src="coverImageUrl | img2WebpFilter" />
    </div>
    <div class="content">
      <div class="banner-title">{{ title }}</div>
      <div class="banner-sub-title">{{ subTitle }}</div>
      <BaseButton
        v-if="buttonText && showBtn"
        background="#fff"
        color="#00CC66"
        width="220px"
        height="52px"
        :text="buttonText"
        @click="onButtonClick"
      />
    </div>
  </section>
</template>

<script>
import BaseButton from '../../common/BaseButton.vue'
import { ua as _ua } from '@yuanfudao/ccgp-common-util'

export default {
  components: {
    BaseButton
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    backgroundColor: {
      type: String,
      require: true
    },
    coverImageUrl: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showBtn: !_ua.isConanClient() && !_ua.isInWechatMP()
    }
  },
  computed: {
    backgroundStyle() {
      return {
        backgroundColor: this.backgroundColor
      }
    }
  },
  methods: {
    onButtonClick() {
      this.$emit('onButtonClick')
    }
  }
}
</script>

<style lang="less" scoped>
.banner-container {
  width: 100%;
  box-sizing: border-box;
  padding: 0.4rem 0.16rem 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: 'PingFang SC';
  font-style: normal;
  color: #ffffff;
  text-shadow: 0.12rem 0.14rem 0.16rem rgba(0, 0, 0, 0.1);
  flex-shrink: 0;

  .content {
    text-align: center;
    margin: 0 auto 0.4rem;
  }

  .banner-title {
    font-weight: 600;
    font-size: 0.48rem;
    line-height: 0.67rem;
    letter-spacing: 0.04em;
  }

  .banner-sub-title {
    font-weight: 400;
    font-size: 0.24rem;
    line-height: 0.34rem;
    margin-bottom: 0.32rem;
    letter-spacing: 0.04em;
  }

  .banner-cover-image {
    font-size: 0;
    width: 3.43rem;
    height: 3.43rem;
    flex-shrink: 0;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
