const onlineConfig = require('./online')
const testConfig = require('./test')
const devConfig = require('./dev')
const stageConfig = require('./stage')

const baseConfig = (function () {
  switch (process.env.WINDOW_ENV) {
    case 'online':
      // 线上环境
      return onlineConfig

    case 'stage':
      // 预上线环境
      return stageConfig

    case 'test':
      // 测试环境
      return testConfig

    case 'dev':
      // 本地开发环境
      return devConfig

    // fallback
    default:
      return onlineConfig
  }
})()

module.exports = Object.assign({}, baseConfig, {
  WINDOW_ENV: process.env.WINDOW_ENV,

  GALLERY_IMAGE_URL: `${baseConfig.GALLERY_URL}/api/ape/images/`,

  PUBLIC_PATH:
    baseConfig.OSS_BUCKET &&
    baseConfig.OSS_MID_PATH &&
    `//${baseConfig.OSS_BUCKET}.fbcontent.cn/${baseConfig.OSS_MID_PATH}/`
})
