import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'
import { Integrations } from '@sentry/tracing'

Sentry.init({
  dsn: 'https://fc57ba644ec540d4b9e9472f836a4fe3@sentry-fe-next.yuanfudao.com/689',

  debug: ['test', 'branch'].includes(process.env.WINDOW_ENV),
  environment: process.env.WINDOW_ENV === 'online' ? 'production' : 'test',
  transport: Sentry.Transports.XHRTransport, // 为了支持IOS-webapp, sentry默认会用fetch，非webapp项目可以不需要这个属性
  release: process.env.GIT_CUR_COMMIT,
  integrations: [
    new VueIntegration({
      Vue,
      tracing: true,
      logErrors: process.env.WINDOW_ENV !== 'online' // 非线上环境将错误信息打印出来
    }),
    new Integrations.BrowserTracing()
  ],
  tracesSampleRate: 0.05
})

Object.defineProperty(Vue, '$sentry', {
  get() {
    return Sentry
  }
})

export default Sentry
