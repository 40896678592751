<template>
  <div class="footer" :class="{ fixed: fixed }">
    <div class="footer-top">
      <div class="footer-item left">
        <span class="title">斑马儿童科教集团</span>
        <div class="download-btn">
          <img src="../../assets/images/app-download-img.png" class="download-sign" />
          <a @click="downloadApp">下载斑马AI学</a>
        </div>
      </div>
      <div class="footer-item mid">
        <span class="title">斑马旗下产品</span>
        <div class="tag-container">
          <div class="tag-wrapper" v-for="(tag, index) in tagList" :key="index">
            <a class="tag" @click="gotoPage(tag)">
              {{ tag.title }}
            </a>
          </div>
        </div>
      </div>
      <div class="footer-item right">
        <span class="title">关注我们</span>
        <div class="focus-box">
          <div v-for="(focusItem, index) in focusList" :key="index" class="focus-icon-box">
            <a
              class="focus-icon"
              target="_blank"
              :href="focusItem.url"
              :style="{ backgroundImage: `url(${focusItem.icon})` }"
              :class="{ 'focus-pointer': !!focusItem.url }"
              @mouseenter="showQrCode(index, focusItem.url)"
              @mouseleave="hideQrCode(index)"
            >
            </a>
            <PQrCodeFloat
              v-if="showQrCodeIdList.includes(index)"
              class="qrcode-float-container"
              :title="focusItem.qrCodeIntroTip"
              :qrCode="focusItem.qrCode"
              position="top"
            />
          </div>
        </div>
        <div class="logo-button" @click="goToYFD">
          <img
            src="https://conan-online.fbcontent.cn/conan-oss-resource/KNZcJvUYoOctBZRE7Zwmmd9GQ0eHopBDc7g7.png"
            alt="logo"
          />
        </div>
      </div>
    </div>
    <div class="btn-line"></div>
    <div class="footer-bottom-content">
      <p>
        <a
          rel="noopener"
          target="_blank"
          href="https://conan.yuanfudao.com/zebra-pages/txt/txt128.html"
          >隐私政策 ｜</a
        >
        <a
          rel="noopener"
          target="_blank"
          href="https://conan.yuanfudao.com/zebra-pages/txt/txt56.html"
          >儿童隐私政策 ｜</a
        >
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://conan-online.fbcontent.cn/conan-oss-resource/Z6XL74XjEqpew9vFkT96lDA4m7UacYYty2rf.jpg"
          >广播电视节目制作经营许可证（京）字第10596号 ｜</a
        >
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://conan-online.fbcontent.cn/conan-oss-resource/wttrQSoEr1PZKC48O6woVor1VIQKkD3yIQPP.jpg"
          >营业执照｜</a
        >
        <a rel="noopener" href="./address.html">公司地址信息｜</a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://conan-online.fbcontent.cn/conan-oss-resource/2X3izjFV4Bw9pp4FcVwAJSXUsGJiJAHeoL6T.jpg"
          >增值电信业务经营许可证</a
        >
      </p>
      <p>
        <a rel="noopener" target="_blank" href="http://beian.miit.gov.cn"
          >京ICP证130009号 ｜ 京ICP备12032703号 ｜</a
        >
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502035049"
        >
          京公网安备11010502034001号 ｜
        </a>
        <span>
          教APP备1102837号 ｜ 京网文[2021] 0984-264号 ｜
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://conan-online.fbcontent.cn/conan-oss-resource/WKRSorMrPHyfqo4BHBmnt6quu0ctEoeSuIoX.jpg"
            >网络经营许可证 ｜</a
          >
          <a
            rel="noopener noreferrer"
            class="copy-right"
            target="_blank"
            href="https://conan-online.fbcontent.cn/conan-oss-resource/HAqvzXzyarLRzS1YC6sU8COxQIV8hl7i3CKE.jpg"
            >出版物经营许可证 ｜</a
          >
          北京猿力科技有限公司 版权所有
        </span>
      </p>
      <p>
        <span>开发者：北京猿力科技有限公司 ｜</span>
        <span class="mar-l-6">版本号：4.31.2 ｜</span>
        <span class="mar-l-6">更新日期：2021-06-01 ｜</span>
        <a
          rel="noopener"
          class="no-text-decoration mar-l-6"
          target="_blank"
          href="https://conan.yuanfudao.com/zebra-pages/ct/ct617.html"
          >应用权限列表 ｜</a
        >
        <a
          rel="noopener"
          class="mar-l-6"
          target="_blank"
          href="https://conan.yuanfudao.com/zebra-pages/txt/txt128.html"
          >用户隐私政策</a
        >
      </p>
      <p>
        <span>违法和不良信息举报电话：400-078-9100 ｜</span>
        <span class="mar-l-6">服务投诉邮箱：jubao365@kanyun.com ｜</span>
        <span class="mar-l-6">廉政举报邮箱：jubao@kanyun.com ｜</span>
        <span class="mar-l-6">客服电话：400-666-0270（周一至周五 9:00-18:00 节假日除外）</span>
      </p>
    </div>
  </div>
</template>

<script>
import PQrCodeFloat from './PQrCodeFloat.vue'
import context from '../../context/index.js'
import tool from '@/utils/index'

export default {
  name: 'WebFooter',
  components: {
    PQrCodeFloat
  },
  props: {
    frogPath: {
      type: String,
      default: ''
    },
    pageType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showQrCodeIdList: [],
      fixed: false,
      tagList: context.themeConfigList,
      focusList: context.focusList,
      currentPageName: ''
    }
  },
  created() {
    this.$nextTick(() => {
      const html = document.documentElement || document.body
      const vh = html.clientHeight
      const height = html.scrollHeight
      if (height <= vh) {
        this.fixed = true
      }
    })
  },
  mounted() {
    this.currentPageName = tool.getCurrentPageName()
  },
  methods: {
    showQrCode(idx, url) {
      if (!url) {
        this.showQrCodeIdList = [idx]
      }
    },
    hideQrCode() {
      this.showQrCodeIdList = []
    },
    gotoPage(config) {
      this.sendEvent(config.tag)
      if (config.tag === 'agebox') {
        window.open(config.jumpUrl, '_blank')
      } else {
        location.href = config.jumpUrl
      }
    },
    downloadApp() {
      const commonParams = [
        { key: 'devicetype', value: 'pc' },
        { key: 'pageType', value: this.pageType },
        { key: 'position', value: 4 }
      ]
      this.$addFrog(this.frogPath, commonParams)
      location.href = './download-center.html'
    },
    sendEvent(tag) {
      const commonParams = [
        { key: 'devicetype', value: 'pc' },
        { key: 'subpage', value: tag },
        { key: 'position', value: 3 },
        { key: 'pageType', value: context.navBarName[this.currentPageName] }
      ]
      this.$addFrog('/click/ZebraAIWebsite/subpage', commonParams)
    },
    goToYFD() {
      location.href = 'https://yuanfudao.com'
    }
  }
}
</script>

<style scoped lang="less">
.footer {
  background: #555;
  width: 100%;
  min-width: 1128px;
}

.footer.fixed {
  position: fixed;
  bottom: 0;
  left: 0;
}

.footer-top {
  display: flex;
  justify-content: center;
  padding-top: 120px;
  box-sizing: border-box;
  height: 367px;
  font-weight: 500;
  color: #fff;

  a {
    display: block;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
  }

  .title {
    font-size: 22px;
    line-height: 31px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .download-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 164px;
    margin-top: 28px;
    line-height: 48px;
    font-size: 14px;
    border: 1px solid #fff;
    border-radius: 48px;
  }

  .download-sign {
    height: 17px;
    width: 20px;
    margin-right: 10px;
  }

  .footer-item {
    width: 360px;
    height: 167px;
    margin: 0 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .mid {
    font-size: 16px;

    .tag-container {
      display: flex;
      flex-wrap: wrap;
    }

    .tag-wrapper {
      display: flex;
      flex-wrap: wrap;
      margin-left: 68px;
      width: 112px;
    }

    .tag-wrapper:nth-child(2n) {
      margin-left: 32px;
    }

    .tag {
      margin-bottom: 19px;
      font-weight: bold;
      white-space: nowrap;
    }
  }

  .right {
    .focus-box {
      display: flex;
      margin: 0 auto;
      font-size: 0;
      text-align: center;

      .focus-icon {
        height: 48px;
        width: 48px;
        border-radius: 50%;
        background-size: contain;
        cursor: default;
      }

      .focus-icon-box {
        position: relative;

        .qrcode-float-container {
          position: absolute;
          bottom: 68px;
          left: -64px;
          box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
        }
      }

      .focus-icon-box + .focus-icon-box {
        margin-left: 20px;
      }

      .focus-pointer {
        cursor: pointer;
      }
    }

    .logo-button {
      margin-top: 20px;
      width: 228px;
      height: 36px;
      font-size: 0;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.btn-line {
  width: 1128px;
  height: 1px;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.24);
  box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.08);
}

.footer-bottom-content {
  margin: 0 auto;
  padding-top: 80px;
  padding-bottom: 110px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
  text-align: center;

  p {
    margin-bottom: 10px;
  }

  a {
    font-size: 12px;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.6);
  }
}

.copy-right {
  margin-left: 6px;
}

.mar-l-6 {
  margin-left: 6px;
}
</style>
