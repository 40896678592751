<template>
  <div class="product-qr-code-wrapper" :class="position">
    <div class="title">{{ title }}</div>
    <div class="qr-code-container">
      <img :src="qrCode | img2WebpFilter" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    qrCode: {
      type: String,
      require: true
    },
    position: {
      type: String,
      default: 'right'
    }
  }
}
</script>

<style lang="less" scoped>
.product-qr-code-wrapper {
  position: relative;
  width: 1.68rem;
  box-sizing: border-box;
  background: #ffffff;

  border-radius: 8px;

  &::before {
    content: '';
    position: absolute;
    width: 0.2rem;
    height: 0.2rem;
    border-radius: 0.03rem;
    background: #ffffff;
  }
  &::after {
    content: '';
    position: absolute;
    width: 0.2rem;
    height: 0.2rem;
    background: #ffffff;
    border-radius: 0.03rem;
    box-shadow: 0 0.04rem 0.16rem rgba(0, 0, 0, 0.08);
    z-index: -1;
  }
  &.right {
    &::before {
      top: 50%;
      left: -0.14rem;
      transform: rotate(45deg) translateY(-50%);
    }
    &::after {
      top: 50%;
      left: -0.14rem;
      transform: rotate(45deg) translateY(-50%);
    }
  }
  &.left {
    &::before {
      top: 50%;
      right: -0.14rem;
      transform: rotate(45deg) translateY(-50%);
    }
    &::after {
      top: 50%;
      right: -0.14rem;
      transform: rotate(45deg) translateY(-50%);
    }
  }
  &.top {
    &::before {
      left: 50%;
      bottom: -0.14rem;
      transform: rotate(45deg) translateX(-50%);
    }
    &::after {
      left: 50%;
      bottom: -0.14rem;
      transform: rotate(45deg) translateX(-50%);
    }
  }
  &.top-right {
    &::before {
      left: 85%;
      bottom: -0.14rem;
      transform: rotate(45deg) translateX(-50%);
    }
    &::after {
      left: 85%;
      bottom: -0.14rem;
      transform: rotate(45deg) translateX(-50%);
    }
  }
  &.top-left {
    &::before {
      left: 15%;
      bottom: -0.14rem;
      transform: rotate(45deg) translateX(-50%);
    }
    &::after {
      left: 15%;
      bottom: -0.14rem;
      transform: rotate(45deg) translateX(-50%);
    }
  }
  &.bottom {
    &::before {
      left: 50%;
      transform: rotate(45deg) translateX(-50%);
    }
    &::after {
      left: 50%;
      top: -14px;
      transform: rotate(45deg) translateX(-50%);
    }
  }
  .title {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 0.14rem;
    line-height: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    margin-top: 0.24rem;
  }
  .qr-code-container {
    width: 1.32rem;
    height: 1.32rem;
    margin: 0.04rem auto 0.2rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
