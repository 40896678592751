<template>
  <div class="product-desc">
    <p class="title">{{ productInfo.title }}</p>
    <p class="desc">
      {{ productInfo.subTitle }}
    </p>
    <div class="product-card">
      <div class="top-box">
        <img class="top" :src="topImageConfig.mobileBgImage | img2WebpFilter" />
        <div @click="jumpPage(topImageConfig)" class="click-btn"></div>
      </div>
      <div class="bottom">
        <div v-for="(img, index) in bottomImageConfigList" :key="index" class="bottom-box">
          <img class="bottom-img" :src="img.mobileBgImage | img2WebpFilter" />
          <div @click="jumpPage(img)" class="click-btn"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    productInfo: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    topImageConfig() {
      const theme = Object.keys(this.productInfo.themeBgConfig)[0]
      return {
        ...this.productInfo.themeBgConfig[theme],
        tag: theme
      }
    },
    bottomImageConfigList() {
      const themeList = Object.keys(this.productInfo.themeBgConfig).slice(1)
      return themeList.map((theme) => {
        return {
          ...this.productInfo.themeBgConfig[theme],
          tag: theme
        }
      })
    }
  },
  methods: {
    jumpPage(config) {
      if (config.jumpUrl) {
        this.sendEvent('/click/ZebraAIWebsite/subpage', 2, config.tag)
        window.location.href = config.jumpUrl
      }
    },
    sendEvent(path, position, pageType) {
      const commonParams = [
        { key: 'devicetype', value: 'mobile' },
        { key: 'pageType', value: 'home' },
        { key: 'position', value: position },
        { key: 'subpage', value: pageType }
      ]
      this.$addFrog(path, commonParams)
    }
  }
}
</script>
<style scoped lang="less">
.product-desc {
  text-align: center;
  padding: 0 0.16rem;
  font-size: 0;
}

.title {
  margin-top: 0.6rem;
  font-weight: 600;
  font-size: 0.2rem;
  line-height: 0.28rem;
  color: #000;
}

.desc {
  margin: 0.16rem auto 0.2rem;
  text-align: center;
  font-size: 0.12rem;
  line-height: 0.18rem;
  color: rgba(0, 0, 0, 0.6);
}

.product-card {
  margin: 0 auto;

  img {
    border-radius: 0.08rem;
  }

  .click-btn {
    position: absolute;
    height: 90px;
    width: 100%;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.top {
  width: 3.43rem;
  height: 4.02rem;
}

.top-box {
  position: relative;
  font-size: 0;
}

.bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .bottom-box {
    position: relative;
    font-size: 0;
  }

  .bottom-img {
    width: 1.64rem;
    height: 2.18rem;
    margin-top: 0.16rem;
  }
}
</style>
