import weixinIcon from '../assets/images/weixin.png'
import xiaohongshuIcon from '../assets/images/xiaohongshu.png'
import weiboIcon from '../assets/images/weibo.png'
import douyinIcon from '../assets/images/douyin.png'
import shipingIcon from '../assets/images/shiping.png'

export default {
  themeConfigList: [
    {
      pcBgImage: 'https://conan-online.fbcontent.cn/conan-oss-resource/0ly5n8ob5.png',
      mobileBgImage: 'https://conan-online.fbcontent.cn/conan-oss-resource/vwtbq6jn0.png',
      bgImage: 'https://conan-online.fbcontent.cn/conan-oss-resource/0ly5n8ob5.png',
      jumpUrl: './banma.html',
      tag: 'zebra',
      title: '斑马AI学'
    },
    {
      bgImage:
        'https://conan-online.fbcontent.cn/conan-oss-resource/q2W8TFCiH5iFCs8C9p99CNRfrBCFWDKzqrDO.png',
      jumpUrl: './baike.html',
      tag: 'encyclopedia',
      title: '斑马百科'
    },
    {
      bgImage:
        'https://conan-online.fbcontent.cn/conan-oss-resource/1L0ne2Oc5sFfNs2pmzb2zikA2cT1PignKJXE.png',
      jumpUrl: './book.html',
      tag: 'book',
      title: '斑马图书'
    },
    {
      bgImage:
        'https://conan-online.fbcontent.cn/conan-oss-resource/7FSk8dOGZEo1yGd1v9PK635f8NByvGUqfypN.png',
      jumpUrl: './toy.html',
      tag: 'goods',
      title: '斑马玩教具'
    },
    {
      bgImage: 'https://conan-online.fbcontent.cn/conan-oss-resource/5wqn10boi.png',
      jumpUrl: 'https://www.xiaobanban.com?keyfrom=bm',
      tag: 'agebox',
      title: '小斑斑'
    }
  ],
  focusList: [
    {
      icon: weixinIcon,
      qrCodeIntroTip: '扫码关注官方微信号',
      qrCode:
        'https://conan-online.fbcontent.cn/conan-oss-resource/m0sr1e1FcK2ORv8vG4iTjX4hJUEGIdmbfaGJ.jpeg'
    },
    {
      icon: xiaohongshuIcon,
      url: 'https://www.xiaohongshu.com/user/profile/5bc44fc6198e2e0001a6c023?xhsshare=CopyLink&appuid=574a9ea782ec391f9dcb38c1&apptime=1666074543'
    },
    {
      icon: weiboIcon,
      url: 'https://weibo.com/u/6428898865'
    },
    {
      icon: douyinIcon,
      url: 'https://v.douyin.com/MDhVc3s/'
    },
    {
      icon: shipingIcon,
      qrCodeIntroTip: '扫码关注官方视频号',
      qrCode:
        'https://conan-online.fbcontent.cn/conan-oss-resource/qo6onQ48HQcxTpaHKNKmjfhl24ChlK2Klvii.png'
    }
  ],
  navBarName: {
    index: 'home',
    banma: 'zebra',
    baike: 'encyclopedia',
    book: 'book',
    toy: 'goods',
    download: 'download',
    undefined: ''
  },
  hotQuestions: [
    {
      question: '1、点读笔连接电脑了，但是助手一直显示“未发现可用点读笔”，怎么处理？',
      answer:
        '若出现该提示，说明点读笔未与电脑连接成功，请尝试拔下数据线后重新连接（请使用原装数据线），或连接其他电脑接口。若多次尝试无果，请联系斑马AI学客服。（请勿删除点读笔内内置文件夹，否则助手可能不能识别点读笔）',
      downloadUrl: ''
    },
    {
      question: '2、电脑是Windows XP及以下系统，APP总提示“安装失败”怎么办？',
      answer:
        '点读笔仅支持win7及以上版本系统，您可以尝试手动下载点读资源，#点此进入点读笔资源包下载页面#。',
      downloadUrl: 'https://banmaapp.com/xp-download-page.html'
    },
    {
      question: '3、更新中断/失败，怎么办？点读笔还能正常使用吗？',
      answer:
        '可以正常使用，未更新成功部分不支持点读。若长时间为“更新失败”的状态，您可以尝试手动下载点读资源，#点此进入点读笔资源包下载页面#。',
      downloadUrl: 'https://banmaapp.com/xp-download-page.html'
    },
    {
      question: '4、点读笔更新内容资源一般需要多久？',
      answer:
        '更新所需时间与待更新的内容资源的大小和网速有关，具体可查看软件更新内容界面的进度显示情况。',
      downloadUrl: ''
    },
    {
      question: '5、什么情况下需要更新点读笔的内容资源？',
      answer:
        '若点读随材时，点读笔发出的语音提示为“没有点读文件，请到官网下载”时，代表您需要更新点读笔的内容资源了。点读笔的内容资源将持续更新增加，需及时更新才能正常使用所有随材。',
      downloadUrl: ''
    },
    {
      question: '6、电脑能够识别点读笔磁盘，但是点读笔助手总提示“未发现可用点读笔”怎么办？',
      answer:
        '您可以查看点读笔磁盘中的“SYS”文件夹是否包含start.bin文件，如果没有，#点此下载缺失文件#，并将下载好的文件拖动至“SYS”文件夹中，并再次连接点读笔助手。',
      downloadUrl:
        'https://conan-online.fbcontent.cn/conan-oss-resource/1604992709994BYQ928377V5/start.bin'
    },
    {
      question: '7、点读笔磁盘中没有任何文件夹怎么办？',
      answer:
        '您可以先#点此下载系统文件#，将下载好的文件解压，将文件夹中的文件全部拖入点读笔磁盘中，再次连接点读笔助手即可。',
      downloadUrl:
        'https://conan-online.fbcontent.cn/conan-oss-resource/1604992797405GSYD543VC3F/点读笔磁盘文件.zip'
    },
    {
      question: '8、我有多支点读笔，一个软件可以同时更新多支点读笔吗？',
      answer:
        '点读笔助手每次可以更新一支点读笔。请更新完一支点读笔，与电脑断开连接后，再连接另一支点读笔更新。',
      downloadUrl: ''
    }
  ],
  baikeConfigList: [
    {
      name: 'wx',
      icon: weixinIcon,
      qrCodeIntroTip: '扫码关注官方微信号',
      qrCode:
        'https://conan-online.fbcontent.cn/conan-oss-resource/79vA3pZzwHJXLjld7lS3w3GDdkTgNnhzZgkX.jpg'
    },
    {
      name: 'xhs',
      icon: xiaohongshuIcon,
      url: 'https://www.xiaohongshu.com/user/profile/621dccd300000000100099cd'
    },
    {
      name: 'dy',
      icon: douyinIcon,
      url: 'https://www.douyin.com/user/MS4wLjABAAAAwVuZSCGTWdmBPYzDLmNdO5rWbIB6NJqcp-1INdGEkBEWJ6QuDLSEk_wH0tXZP_IW'
    },
    {
      name: 'sph',
      icon: shipingIcon,
      qrCodeIntroTip: '扫码关注官方视频号',
      qrCode:
        'https://conan-online.fbcontent.cn/conan-oss-resource/vNhtxrDM1qznUaqQGjwNaFaZZjTieMGM88SY.jpg'
    }
  ],
  baikeFooterList: [
    {
      title: 'iOS下载',
      qrCode:
        'https://conan-online.fbcontent.cn/conan-oss-resource/mQfUrEugD6YfLTyV7sm3t1Ks22h5O3zS8StL.png'
    },
    {
      title: 'Android下载',
      qrCode:
        'https://conan-online.fbcontent.cn/conan-oss-resource/70xVoJs4W2UDzFG9cdeHngcK0oylzKIpGQeo.png'
    }
  ]
}
