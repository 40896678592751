module.exports = {
  GALLERY_URL: '//gallery.yuanfudao.com',
  GALLERY_CDN: '//gallery.fbcontent.cn',
  BASE_URL: 'https://conan.yuanfudao.com/h5/conan-web-page',
  APE_WEB_PROXY_URL: '//ytk.yuanfudao.com/ape-web-proxy/api/wechat',
  YTK_HOST: '//ytk.yuanfudao.com',
  CONAN_HOST: '//conan.yuanfudao.com',
  CONAN1_HOST: '//conan1.yuanfudao.com',
  CONAN2_HOST: '//conan2.yuanfudao.com',
  BMYY_WECHAT_NAME: 'bmyy-pay', // 斑马英语服务号支付
  BMYY_WECHAT_APPID: 'wx08a38e2646380ba2',
  OSS_IMAGE_URL: '//conan-online.fbcontent.cn/conan-english',
  PROTOCOL: 'https:',
  ENGLISH_SYS: 20,
  ENGLISH_TRIAL: 126,
  MATH_TRIAL: 70,
  MATH_SYS: 46,
  CHINESE_TRIAL: 122,
  CHINESE_SYS: 118,
  PLANET_URL: '//planet.yuanfudao.com',

  // ---- OSS 配置 ----- 线上环境不允许本地上传
  OSS_BUCKET: 'static-nginx-online',
  OSS_MID_PATH: 'h5/zebra-ai-page'
  // ---- OSS 配置 -----
}
