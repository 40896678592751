<template>
  <header class="header">
    <img
      class="logo"
      src="https://conan-online.fbcontent.cn/conan-oss-resource/0vafHJiN7lMJYO3DAR09kEWYHxtkyE3tmJIy.png"
      alt="斑马"
      @click="gotoPage(navBarList[0], 0)"
    />
    <div @click="openDialog" class="nav-btn">
      <div class="line" />
      <div class="line" />
      <div class="line" />
    </div>

    <div v-show="showDialog" class="nav-dialog">
      <div @click="closeDialog" class="overlay" />
      <div class="nav-box">
        <div class="nav-header">
          <img
            class="logo"
            src="https://conan-online.fbcontent.cn/conan-oss-resource/0vafHJiN7lMJYO3DAR09kEWYHxtkyE3tmJIy.png"
            alt="斑马"
          />
          <img @click="closeDialog" class="close-img" src="../../assets/images/close.png" />
        </div>
        <div class="nav-list">
          <div
            v-for="(nav, index) in navBarList"
            :key="index"
            class="nav-item hairline-bottom"
            :class="{ current: +current === index }"
            @click="gotoPage(nav, index)"
          >
            {{ nav.title }}
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import tool from '@/utils/index'
import context from '../../context/index'
import { ua as _ua } from '@yuanfudao/ccgp-common-util'

export default {
  props: {
    current: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      navBarList: _ua.isConanClient()
        ? [
            { title: '首页', url: './index.html', tag: 'home' },
            { title: '斑马AI学', url: './banma.html', tag: 'zebra' },
            {
              title: '斑马百科',
              url: './baike.html',
              tag: 'encyclopedia'
            },
            { title: '斑马图书', url: './book.html', tag: 'book' },
            { title: '斑马玩教具', url: './toy.html', tag: 'goods' },
            {
              title: '小斑斑',
              url: 'https://www.xiaobanban.com?keyfrom=bm',
              tag: 'agebox'
            },
            {
              title: '技术支持',
              url: 'https://conan.yuanfudao.com/yun-webapp/conan-webapp-ai-service/page-customer-service/latest/customer-service.html?loginType=2&configId=da635d44b56ecf8e47ca6e2d30c206a3',
              tag: 'consult'
            }
          ]
        : [
            { title: '首页', url: './index.html', tag: 'home' },
            { title: '斑马AI学', url: './banma.html', tag: 'zebra' },
            {
              title: '斑马百科',
              url: './baike.html',
              tag: 'encyclopedia'
            },
            { title: '斑马图书', url: './book.html', tag: 'book' },
            { title: '斑马玩教具', url: './toy.html', tag: 'goods' },
            {
              title: '小斑斑',
              url: 'https://www.xiaobanban.com?keyfrom=bm',
              tag: 'agebox'
            },
            {
              title: '下载中心',
              url: './download-center.html',
              tag: 'download'
            },
            {
              title: '技术支持',
              url: 'https://conan.yuanfudao.com/yun-webapp/conan-webapp-ai-service/page-customer-service/latest/customer-service.html?loginType=2&configId=da635d44b56ecf8e47ca6e2d30c206a3',
              tag: 'consult'
            }
          ],
      showDialog: false,
      currentPageName: ''
    }
  },
  mounted() {
    this.currentPageName = tool.getCurrentPageName()
  },
  methods: {
    gotoPage(navItem, index) {
      this.closeDialog()
      if (+this.current === index) return
      this.sendEvent(navItem.tag)
      location.href = navItem.url
    },
    openDialog() {
      this.showDialog = true
    },
    closeDialog() {
      this.showDialog = false
    },
    sendEvent(pageType) {
      const commonParams = [
        { key: 'devicetype', value: 'mobile' },
        { key: 'pageType', value: context.navBarName[this.currentPageName] },
        { key: 'subpage', value: pageType },
        { key: 'position', value: 1 }
      ]
      this.$addFrog('/click/ZebraAIWebsite/subpage', commonParams)
    }
  }
}
</script>
<style scoped lang="less">
.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  height: 0.56rem;
  padding: 0 0.16rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000;
  background: #fff;
}
.logo {
  height: 0.32rem;
  width: 2.12rem;
}
.line {
  width: 0.2rem;
  height: 0.03rem;
  background: #000;
  border-radius: 0.03rem;
}
.line + .line {
  margin-top: 0.04rem;
}
.nav-dialog {
  z-index: 99;
  .overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
  .nav-box {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    background: #fff;
  }
  .nav-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 0.56rem;
    padding: 0 0.16rem;
  }
  .close-img {
    height: 0.24rem;
    width: 0.24rem;
  }
  .nav-list {
    padding: 0 0.32rem;
    box-sizing: border-box;
    font-size: 0.16rem;
  }
  .current {
    color: #00cc66;
  }
  .nav-item {
    line-height: 0.22rem;
    padding-top: 0.17rem;
    padding-bottom: 0.2rem;
  }
}
</style>
