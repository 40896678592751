<template>
  <div class="page-turning">
    <img
      v-if="listLen > 3"
      class="page-turn-icon"
      :src="pageUpImg"
      @click="forwardCard"
      @mouseenter="changeUpIcon(true)"
      @mouseleave="changeUpIcon(false)"
    />
    <div class="card-wrapper" ref="cardsRef" :style="{ maxWidth: `${cardWrapperWidth}px` }">
      <div class="card-items" :style="{ transform: `translateX(${-translateX}px)` }">
        <slot></slot>
      </div>
    </div>
    <img
      v-if="listLen > 3"
      class="page-turn-icon"
      :src="pageDownImg"
      @click="nextCard"
      @mouseenter="changeDownIcon(true)"
      @mouseleave="changeDownIcon(false)"
    />
  </div>
</template>
<script>
import pageUpHover from '../../assets/images/page-up.png'
import pageUpDisable from '../../assets/images/page-up-disable.png'
import pageDownHover from '../../assets/images/page-down.png'
import pageDownDisable from '../../assets/images/page-down-disable.png'
import pageDownNormal from '../../assets/images/page-down-normal.png'
import pageUpNormal from '../../assets/images/page-up-normal.png'

export default {
  props: {
    listLen: {
      type: Number,
      default: 0
    },
    cardWrapperWidth: {
      type: Number,
      default: 1128
    },
    cardWidth: {
      type: Number,
      default: 384
    }
  },
  data() {
    return {
      translateX: 0,
      hoverUp: false,
      hoverDown: false
    }
  },
  computed: {
    maxTranslateLength() {
      return (this.listLen - 3) * this.cardWidth
    },
    pageDownImg() {
      if (this.hoverDown) {
        return pageDownHover
      }
      return this.translateX >= this.maxTranslateLength ? pageDownDisable : pageDownNormal
    },
    pageUpImg() {
      if (this.hoverUp) {
        return pageUpHover
      }
      return this.translateX <= 0 ? pageUpDisable : pageUpNormal
    }
  },
  methods: {
    changeUpIcon(status) {
      this.hoverUp = status
    },
    changeDownIcon(status) {
      this.hoverDown = status
    },
    forwardCard() {
      if (this.translateX > 0) {
        this.translateX -= this.cardWidth
      }
    },
    nextCard() {
      if (this.translateX < this.maxTranslateLength) {
        this.translateX += this.cardWidth
      }
    }
  }
}
</script>
<style scoped lang="less">
.page-turning {
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-turn-icon {
  height: 100px;
  width: 80px;
}
.card-wrapper {
  overflow: hidden;
  margin: 0 8px;
}
.card-items {
  display: flex;
  transition: transform 0.5s;
}
</style>
