<template>
  <div :style="{ height: cardHeight }" class="card">
    <div class="img-card" :style="{ height: imgHeight, backgroundImage: `url(${imgUrl})` }"></div>
    <div class="content">
      <div v-if="title" class="title" :class="{ 'normal-title': titleType === 'normal' }">
        {{ title }}
      </div>
      <div v-if="desc" class="desc">{{ desc }}</div>
      <a v-if="detailInfo" class="more" :href="detailInfo">了解详情</a>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    titleType: {
      type: String,
      default: ''
    },
    cardHeight: {
      type: String,
      default: ''
    },
    imgHeight: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    desc: {
      type: String,
      default: ''
    },
    detailInfo: {
      type: String,
      default: ''
    },
    imgUrl: {
      type: String,
      default: ''
    }
  }
}
</script>
<style scoped lang="less">
.card {
  position: relative;
  flex-shrink: 0;
  width: 2.8rem;
  border-radius: 0.08rem;
  background: #fff;
  overflow: hidden;
}
.img-card {
  background-size: cover;
  background-repeat: no-repeat;
}
.content {
  padding: 0 0.16rem;
  box-sizing: border-box;
}
.title {
  font-weight: 600;
  font-size: 0.16rem;
  line-height: 0.22rem;
  margin: 0.16rem 0 0.08rem;
}
.normal-title {
  font-weight: normal;
}
.desc {
  font-size: 0.12rem;
  line-height: 0.18rem;
  color: rgba(0, 0, 0, 0.6);
}
.more {
  position: absolute;
  right: 0.32rem;
  bottom: 0.4rem;
  font-size: 0.16rem;
  line-height: 0.22rem;
  color: #0090d9;
}
</style>
