import { ua as _ua } from '@yuanfudao/ccgp-common-util'

export default {
  getDownloadAppUrl() {
    let url = 'https://banmaaike.com/vendor-download/?vendor=website'
    if (_ua.isIOS()) {
      url = 'https://itunes.apple.com/cn/app/id1279609813'
    }
    return url
  },
  getBaikeDownloadAppUrl() {
    return 'https://conan.yuanfudao.com/class-touch/invoke.html?appType=pedia&nativeUrl=native%3A%2F%2FhomeTab%3Findex%3D0'
  },
  getCurrentPageName() {
    const url = location.href
    const path = url.split('/').pop()
    const reg = /(.*)\.html/
    const matchList = path.match(reg) || []
    if (matchList.length > 1) {
      return matchList[1]
    }
    return 'index'
  }
}
