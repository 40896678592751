<template>
  <!-- PC 端 banner, 纯 UI 组件 -->
  <section class="banner-container" :style="backgroundStyle">
    <div class="banner-info-container">
      <div class="banner-title">{{ title }}</div>
      <div class="banner-sub-title">{{ subTitle }}</div>
      <div class="banner-desc">{{ description }}</div>
      <BaseButton
        v-if="buttonText"
        background="#fff"
        color="#00CC66"
        height="72px"
        :text="buttonText"
        @click="onButtonClick"
      />
      <div class="card-wrapper">
        <img
          v-for="item in imgListUrl"
          :key="item.imgUrl"
          :src="item.imgUrl"
          class="img-card"
          @click="jumpPage(item)"
        />
      </div>
    </div>
    <div class="banner-cover-image">
      <img v-if="coverImageUrl" :src="coverImageUrl | img2WebpFilter" />
    </div>
  </section>
</template>

<script>
import BaseButton from '../common/BaseButton.vue'
export default {
  components: {
    BaseButton
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    backgroundColor: {
      type: String,
      require: true
    },
    coverImageUrl: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: ''
    },
    imgListUrl: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    backgroundStyle() {
      return {
        backgroundColor: this.backgroundColor
      }
    }
  },
  methods: {
    onButtonClick() {
      this.$emit('onButtonClick')
    },
    jumpPage(item) {
      if (item.jumpUrl) {
        this.sendEvent(item.type)
        location.href = item.jumpUrl
      }
    },
    sendEvent(type) {
      const commonParams = [
        { key: 'devicetype', value: 'pc' },
        { key: 'pageType', value: 'download' },
        { key: 'type', value: type }
      ]
      this.$addFrog('/click/ZebraAIDownloadCenter/downloadButton', commonParams)
    }
  }
}
</script>

<style lang="less" scoped>
.banner-container {
  height: 500px;
  width: 100%;
  margin-top: 80px;
  min-width: 1128px;
  display: flex;
  justify-content: center;
  .banner-info-container {
    font-family: 'PingFang SC';
    font-style: normal;
    color: #ffffff;
    padding-top: 100px;
    text-shadow: 12px 14px 16px rgba(0, 0, 0, 0.1);
    width: 400px;
    margin-right: 72px;
    flex-shrink: 0;
    .banner-title {
      font-weight: 600;
      font-size: 72px;
      line-height: 102px;
      letter-spacing: 0.08em;
      margin-bottom: 8px;
    }
    .banner-sub-title {
      font-weight: 400;
      font-size: 32px;
      line-height: 46px;
      margin-bottom: 32px;
      letter-spacing: 8px;
    }
    .banner-desc {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: justify;
    }
  }
  .card-wrapper {
    display: flex;
    font-size: 0;
    .img-card {
      height: 100px;
      width: 100px;
      border-radius: 8px;
      cursor: pointer;
    }
    .img-card + .img-card {
      margin-left: 16px;
    }
  }
  .banner-cover-image {
    font-size: 0;
    width: 500px;
    height: 500px;
    flex-shrink: 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
