import Vue from 'vue'
import '../../assets/css/reset.css'
import MHome from './views/MHome'
import PHome from './views/PHome'
import { query as _query, ua as _ua } from '@yuanfudao/ccgp-common-util'
import frog from '@/plugins/frog'
import webTrack from '@/plugins/webTrack'
import { Img2WebpPlugin } from '@yuanfudao/conan-webp-sdk'
import '../../utils/sentry'

Vue.use(Img2WebpPlugin)
Vue.use(frog)
Vue.use(webTrack, { pageName: 'home-page' })

const query = _query.parse()

let option = {
  template: '<PHome />',
  components: { PHome }
}
let devicetype = 'pc'

if (_ua.isMobile()) {
  option = {
    template: '<MHome />',
    components: { MHome }
  }
  devicetype = 'mobile'
}

const vue = new Vue({
  el: '#app',
  data: { query },
  ...option
})

vue.$addFrog('/event/ZebraAIWebsite/enter', [
  { key: 'pageType', value: 'home' },
  { key: 'devicetype', value: devicetype }
])
