<template>
  <div class="news-and-report">
    <p class="title">{{ mediaInfo.title }}</p>
    <PPageTurning :listLen="newsList.length">
      <PNewsCard
        v-for="(newsItem, index) in newsList"
        :key="index"
        :detailInfo="newsItem.jumpUrl"
        :title="newsItem.title"
        :desc="newsItem.content"
        :imgUrl="newsItem.imgUrl"
        class="news-card"
        cardHeight="460px"
        imgHeight="160px"
      />
    </PPageTurning>
  </div>
</template>
<script>
import PNewsCard from '../PNewsCard.vue'
import PPageTurning from '../PPageTurning.vue'
export default {
  components: {
    PNewsCard,
    PPageTurning
  },
  props: {
    mediaInfo: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    newsList() {
      return this.mediaInfo.newsList || []
    }
  }
}
</script>
<style scoped lang="less">
.title {
  margin-top: 128px;
  margin-bottom: 64px;
  font-weight: 600;
  font-size: 40px;
  line-height: 56px;
  text-align: center;
  color: #000;
}
.news-card + .news-card {
  margin-left: 24px;
}
</style>
