<template>
  <div :style="{ height: cardHeight }" class="card">
    <div class="img-card" :style="{ height: imgHeight, backgroundImage: `url(${imgUrl})` }"></div>
    <div class="content">
      <div v-if="title" class="title" :class="{ 'normal-title': titleType === 'normal' }">
        {{ title }}
      </div>
      <div v-if="desc" class="desc">{{ desc }}</div>
      <a v-if="detailInfo" class="more" :href="detailInfo">了解详情</a>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    titleType: {
      type: String,
      default: ''
    },
    cardHeight: {
      type: String,
      default: ''
    },
    imgHeight: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    desc: {
      type: String,
      default: ''
    },
    detailInfo: {
      type: String,
      default: ''
    },
    imgUrl: {
      type: String,
      default: ''
    }
  }
}
</script>
<style scoped lang="less">
.card {
  position: relative;
  flex-shrink: 0;
  width: 360px;
  border-radius: 8px;
  background: #fff;
  overflow: hidden;
}
.img-card {
  background-size: contain;
}
.content {
  padding: 0 32px;
  box-sizing: border-box;
}
.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin: 32px 0 16px;
}
.normal-title {
  font-weight: normal;
}
.desc {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.6);
}
.more {
  position: absolute;
  right: 32px;
  bottom: 40px;
  font-size: 16px;
  line-height: 22px;
  color: #0090d9;
  text-decoration: none;
}
</style>
