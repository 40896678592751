<template>
  <div class="footer">
    <div class="top">
      <p class="title">斑马儿童科教集团</p>
      <div class="qrcode-box">
        <div class="left">
          <div>
            <img class="qrcode-scan-sign" src="../../assets/images/scan-sign.png" />
            <p class="notice">扫码关注斑马官方公众号</p>
          </div>
          <p class="desc">了解官方信息，领取官方福利</p>
        </div>
        <img
          class="qrcode-img"
          src="https://conan-online.fbcontent.cn/conan-oss-resource/m0sr1e1FcK2ORv8vG4iTjX4hJUEGIdmbfaGJ.jpeg"
        />
      </div>
      <p class="title">斑马儿童科教集团</p>
      <div class="button-box">
        <BaseButton
          v-for="(button, index) in normalButtonList"
          :key="index"
          @click="gotoPage(button)"
          class="button-item"
          :text="button.title"
          width="104px"
          height="36px"
          background="rgba(255, 255, 255, 0.04)"
          fontSize="14px"
        />
        <BaseButton
          class="button-item"
          @click="gotoPage(bigButtonInfo)"
          :text="bigButtonInfo.title"
          width="100%"
          height="36px"
          background="rgba(255, 255, 255, 0.04)"
          fontSize="14px"
        />
      </div>
      <p class="title">关注我们</p>
      <div class="focus-box">
        <div v-for="(focusItem, index) in focusList" :key="index" class="focus-icon-box">
          <div
            :style="{ backgroundImage: `url(${focusItem.icon})` }"
            class="focus-icon"
            @click="showQrCode(index, focusItem.url)"
          ></div>
          <MQrCodeFloat
            v-if="showQrCodeIdList.includes(index)"
            class="qrcode-float"
            :class="qrcodePosition(index).class"
            :title="focusItem.qrCodeIntroTip"
            :qrCode="focusItem.qrCode"
            :position="qrcodePosition(index).position"
          />
        </div>
      </div>
      <div class="logo-button" @click="goToYFD">
        <img
          src="https://conan-online.fbcontent.cn/conan-oss-resource/KNZcJvUYoOctBZRE7Zwmmd9GQ0eHopBDc7g7.png"
          alt="logo"
        />
      </div>
    </div>
    <div class="btn-line"></div>
    <div class="jubao-wrap">
      <p>
        <a
          rel="noopener"
          class="no-text-decoration"
          target="_blank"
          href="https://conan.yuanfudao.com/zebra-pages/txt/txt128.html"
          >隐私政策 ｜</a
        >
        <a
          rel="noopener"
          class="no-text-decoration"
          target="_blank"
          href="https://conan.yuanfudao.com/zebra-pages/txt/txt56.html"
          >儿童隐私政策 ｜</a
        >
        <a
          rel="noopener"
          class="no-text-decoration"
          target="_blank"
          href="https://conan.yuanfudao.com/zebra-pages/ct/ct617.html"
          >应用权限列表</a
        >
      </p>
      <p>
        <a rel="noopener" class="no-text-decoration" target="_blank" href="http://beian.miit.gov.cn"
          >京ICP备12032703号 ｜ 京ICP证130009号</a
        >
      </p>
      <p>
        <a
          rel="noopener noreferrer"
          class="no-text-decoration"
          target="_blank"
          href="https://conan-online.fbcontent.cn/conan-oss-resource/Z6XL74XjEqpew9vFkT96lDA4m7UacYYty2rf.jpg"
          >广播电视节目制作经营许可证（京）字第10596号</a
        >
      </p>
      <p>
        <a
          rel="noopener"
          class="no-text-decoration"
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502035049"
        >
          京公网安备11010502034001号 ｜
        </a>
        <a
          rel="noopener"
          class="no-text-decoration"
          target="_blank"
          href="https://conan-online.fbcontent.cn/conan-oss-resource/wttrQSoEr1PZKC48O6woVor1VIQKkD3yIQPP.jpg"
          >营业执照 ｜</a
        >
        <a rel="noopener" class="no-text-decoration" target="_blank" href="./address.html"
          >公司地址信息</a
        >
      </p>
      <p>
        <a
          rel="noopener noreferrer"
          class="no-text-decoration"
          target="_blank"
          href="https://conan-online.fbcontent.cn/conan-oss-resource/2X3izjFV4Bw9pp4FcVwAJSXUsGJiJAHeoL6T.jpg"
          >增值电信业务经营许可证</a
        >
      </p>
      <p>
        <a
          rel="noopener noreferrer"
          class="no-text-decoration"
          target="_blank"
          href="https://conan-online.fbcontent.cn/conan-oss-resource/WKRSorMrPHyfqo4BHBmnt6quu0ctEoeSuIoX.jpg"
          >网络经营许可证 ｜</a
        >
        <a
          rel="noopener noreferrer"
          class="no-text-decoration"
          target="_blank"
          href="https://conan-online.fbcontent.cn/conan-oss-resource/HAqvzXzyarLRzS1YC6sU8COxQIV8hl7i3CKE.jpg"
          >出版物经营许可证</a
        >
      </p>
      <p>开发者：北京猿力科技有限公司</p>
      <p>版本号：4.31.2 更新日期：2021-06-01</p>
      <p>服务投诉邮箱：jubao365@kanyun.com</p>
      <p>廉政举报邮箱：jubao@kanyun.com</p>
      <p>客服电话：400-666-0270（周一至周五9:00-18:00 节假日除外)</p>
      <p>教APP备1102837号 | 京网文[2021] 0984-264号</p>
    </div>
  </div>
</template>

<script>
import BaseButton from '../common/BaseButton.vue'
import MQrCodeFloat from './MQrCodeFloat.vue'
import context from '../../context/index.js'
import tool from '@/utils/index'

export default {
  name: 'MobileFooter',
  components: {
    BaseButton,
    MQrCodeFloat
  },
  data() {
    return {
      showQrCodeIdList: [],
      focusList: context.focusList,
      currentPageName: ''
    }
  },
  mounted() {
    this.currentPageName = tool.getCurrentPageName()
  },
  computed: {
    normalButtonList() {
      const len = context.themeConfigList.length
      return context.themeConfigList.slice(0, len - 1)
    },
    bigButtonInfo() {
      const len = context.themeConfigList.length
      return context.themeConfigList[len - 1]
    },

    qrcodePosition() {
      return function (index) {
        if (index === 0) {
          return {
            class: 'qrcode-float-left',
            position: 'top-left'
          }
        } else if (index === this.focusList.length - 1) {
          return {
            class: 'qrcode-float-right',
            position: 'top-right'
          }
        } else {
          return {
            class: '',
            position: 'top'
          }
        }
      }
    }
  },
  methods: {
    gotoPage(config) {
      this.sendEvent(config.tag)
      location.href = config.jumpUrl
    },
    showQrCode(idx, url) {
      if (this.showQrCodeIdList.includes(idx)) {
        this.showQrCodeIdList = []
      } else if (!url) {
        this.showQrCodeIdList = [idx]
      } else {
        window.location.href = url
      }
    },
    sendEvent(pageType) {
      const commonParams = [
        { key: 'devicetype', value: 'mobile' },
        { key: 'pageType', value: context.navBarName[this.currentPageName] },
        { key: 'subpage', value: pageType },
        { key: 'position', value: 3 }
      ]
      this.$addFrog('/click/ZebraAIWebsite/subpage', commonParams)
    },
    goToYFD() {
      location.href = 'https://yuanfudao.com'
    }
  }
}
</script>

<style scoped lang="less">
.footer {
  background: #555;
}

.top {
  color: #fff;
  padding-top: 0.6rem;

  .title {
    font-size: 0.2rem;
    line-height: 0.28rem;
    text-align: center;
    font-weight: 600;
  }

  .qrcode-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 0.88rem;
    margin: 0.2rem 0.16rem 0.6rem;
    padding: 0.16rem;
    box-sizing: border-box;
    border-radius: 0.12rem;
    background: rgba(255, 255, 255, 0.04);

    .left {
      flex: 1;
    }

    .qrcode-scan-sign {
      height: 0.2rem;
      width: 0.2rem;
      float: left;
      margin-right: 0.04rem;
    }

    .qrcode-img {
      height: 0.56rem;
      width: 0.56rem;
    }

    .notice {
      font-weight: 500;
      font-size: 0.14rem;
      line-height: 0.2rem;
    }

    .desc {
      margin-top: 0.08rem;
      font-size: 0.12rem;
      line-height: 0.18rem;
      color: rgba(255, 255, 255, 0.6);
    }
  }

  .button-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 2.28rem;
    margin: 0.2rem auto 0;

    .button-item {
      margin-bottom: 0.2rem;
    }

    .button-item:last-child {
      margin-bottom: 0.6rem;
    }
  }

  .focus-box {
    display: flex;
    justify-content: center;
    margin: 0.16rem auto 0.2rem;

    .focus-icon {
      height: 0.48rem;
      width: 0.48rem;
      border-radius: 50%;
      background-size: contain;
    }

    .focus-icon-box {
      position: relative;

      .qrcode-float {
        position: absolute;
        bottom: 68px;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
      }

      .qrcode-float-left {
        left: 0;
      }

      .qrcode-float-right {
        right: 0;
      }
    }

    .focus-icon-box + .focus-icon-box {
      margin-left: 20px;
    }
  }

  .logo-button {
    margin: 0 auto 0.4rem;
    width: 2.28rem;
    height: 0.36rem;
    font-size: 0;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.btn-line {
  height: 0.01rem;
  margin: 0 0.16rem;
  background: rgba(0, 0, 0, 0.24);
  box-shadow: 0 0.01rem 0 rgba(255, 255, 255, 0.08);
}

.jubao-wrap {
  padding-top: 0.4rem;
  padding-bottom: 0.6rem;
  text-align: center;
  font-size: 0.12rem;
  color: rgba(255, 255, 255, 0.6);
  line-height: 0.18rem;
}

.jubao-wrap p {
  margin: 0.04rem 0;
}

.no-text-decoration {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.6);
}
</style>
