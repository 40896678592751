<template>
  <section class="index-home">
    <PAboutUs />
    <PNavBar :current="0" />
    <PLoadError v-if="pageLoadStatus !== 'success'" :loadStatus="pageLoadStatus" />
    <section v-if="pageLoadStatus === 'success'">
      <PBanner
        buttonText="立即体验"
        :coverImageUrl="bannerConfig.coverImageUrl"
        :backgroundColor="bannerConfig.backgroundColor"
        :title="bannerConfig.title"
        :subTitle="bannerConfig.subTitle"
        @onButtonClick="onBannerButtonClick(1)"
      ></PBanner>
      <PProductsDesc :productInfo="productInfo" />
      <PHonorDesc :honorInfo="honorInfo" />
      <PMediaReport :mediaInfo="mediaInfo" />
      <BaseButton
        class="bottom-btn"
        text="立即了解斑马AI学"
        :skeleton="true"
        height="72px"
        @click="onBannerButtonClick(3)"
      />
    </section>
    <PFooter frogPath="/click/ZebraAIWebsite/download" pageType="home" />
  </section>
</template>
<script>
import PLoadError from '../../../components/pc/PLoadError.vue'
import PAboutUs from '../../../components/pc/index/PAboutUs.vue'
import PNavBar from '../../../components/pc/PNavBar.vue'
import PBanner from '../../../components/pc/PBanner.vue'
import PFooter from '../../../components/pc/PFooter.vue'
import PHonorDesc from '../../../components/pc/index/PHonorDesc.vue'
import PProductsDesc from '../../../components/pc/index/PProductsDesc.vue'
import PMediaReport from '../../../components/pc/index/PMediaReport.vue'
import BaseButton from '../../../components/common/BaseButton.vue'
import OperationService from '../../../service/operation'

export default {
  components: {
    PAboutUs,
    PNavBar,
    PBanner,
    PHonorDesc,
    PFooter,
    PProductsDesc,
    PMediaReport,
    BaseButton,
    PLoadError
  },
  data() {
    return {
      pageLoadStatus: 'loading',
      bannerConfig: {},
      productInfo: {},
      honorInfo: {},
      mediaInfo: {},
      startTime: 0
    }
  },
  mounted() {
    this.getDuringTime()
    this.getPageConfig()
    const timpStamp = window.performance.now()
    this.$webTrack.sendMetrics({
      name: 'FMP',
      value: timpStamp,
      delta: timpStamp
    })
  },
  methods: {
    getDuringTime() {
      this.startTime = Date.now()
      window.onbeforeunload = function () {
        const now = Date.now()
        const times = now - this.startTime
        this.sendEvent('/time/ZebraAIWebsite/duration', [{ key: 'duration', value: times }])
      }.bind(this)
    },
    onBannerButtonClick(position) {
      this.sendEvent('/click/ZebraAIWebsite/download', [{ key: 'position', value: position }])
      location.href = './download-center.html'
    },
    getPageConfig() {
      OperationService.fetchIndexConfig()
        .then((data) => {
          this.pageLoadStatus = 'success'
          const { bannerConfig, productInfo, honorInfo, mediaInfo } = data
          this.bannerConfig = bannerConfig
          this.productInfo = productInfo
          this.honorInfo = honorInfo
          this.mediaInfo = mediaInfo
        })
        .catch(() => {
          this.pageLoadStatus = 'error'
        })
    },
    sendEvent(path, addParams = []) {
      const commonParams = [
        { key: 'devicetype', value: 'pc' },
        { key: 'pageType', value: 'home' }
      ]
      this.$addFrog(path, [...commonParams, ...addParams])
    }
  }
}
</script>
<style scoped lang="less">
.index-home {
  background: #f7f7f7;
  min-width: 1128px;
}
.page-load-error {
  height: 100vh;
  background: #fafafa;
}
.bottom-btn {
  margin: 128px auto;
}
</style>
