<template>
  <section class="index-home">
    <MNavBar :current="0" />
    <section v-if="pageLoadStatus !== 'success'"></section>
    <section v-if="pageLoadStatus === 'success'">
      <MBanner
        buttonText="立即体验"
        :coverImageUrl="bannerConfig.coverImageUrl"
        :backgroundColor="bannerConfig.backgroundColor"
        :title="bannerConfig.title"
        :subTitle="bannerConfig.subTitle"
        @onButtonClick="onBannerButtonClick(1)"
      ></MBanner>
      <MProductsDesc :productInfo="productInfo" />
      <MHonorDesc :honorInfo="honorInfo" />
      <MMediaReport :mediaInfo="mediaInfo" />
      <BaseButton
        v-if="showBtn"
        class="bottom-btn"
        width="200px"
        height="48px"
        fontSize="16px"
        text="立即了解斑马AI学"
        :skeleton="true"
        @click="onBannerButtonClick(3)"
      />
      <MFooter />
      <MFloatDownloadTip frogPath="/click/ZebraAIWebsite/download" pageType="home" />
    </section>
  </section>
</template>
<script>
import MNavBar from '../../../components/mobile/MNavBar.vue'
import MFloatDownloadTip from '../../../components/mobile/MFloatDownloadTip.vue'
import MBanner from '../../../components/mobile/index/MBanner.vue'
import MProductsDesc from '../../../components/mobile/index/MProductsDesc.vue'
import MHonorDesc from '../../../components/mobile/index/MHonorDesc.vue'
import MMediaReport from '../../../components/mobile/index/MMediaReport.vue'
import BaseButton from '../../../components/common/BaseButton.vue'
import MFooter from '../../../components/mobile/MFooter.vue'
import OperationService from '../../../service/operation'
import { ua as _ua } from '@yuanfudao/ccgp-common-util'
import tool from '@/utils/index'

export default {
  components: {
    MNavBar,
    MBanner,
    MProductsDesc,
    MHonorDesc,
    MMediaReport,
    BaseButton,
    MFooter,
    MFloatDownloadTip
  },
  data() {
    return {
      pageLoadStatus: 'loading',
      bannerConfig: {},
      productInfo: {},
      honorInfo: {},
      mediaInfo: {},
      showBtn: !_ua.isConanClient() && !_ua.isInWechatMP()
    }
  },
  mounted() {
    this.getDuringTime()
    this.getPageConfig()
    const timpStamp = window.performance.now()
    this.$webTrack.sendMetrics({
      name: 'FMP',
      value: timpStamp,
      delta: timpStamp
    })
  },
  methods: {
    getDuringTime() {
      this.startTime = Date.now()
      window.addEventListener('pagehide', () => {
        const now = Date.now()
        const times = now - this.startTime
        this.sendEvent('/time/ZebraAIWebsite/duration', [{ key: 'duration', value: times }])
      })
    },
    onBannerButtonClick(position) {
      this.sendEvent('/click/ZebraAIWebsite/download', [{ key: 'position', value: position }])
      location.href = tool.getDownloadAppUrl()
    },
    getPageConfig() {
      OperationService.fetchIndexConfig()
        .then((data) => {
          this.pageLoadStatus = 'success'
          const { bannerConfig, productInfo, honorInfo, mediaInfo } = data
          this.bannerConfig = bannerConfig
          this.productInfo = productInfo
          this.honorInfo = honorInfo
          this.mediaInfo = mediaInfo
        })
        .catch(() => {
          this.pageLoadStatus = 'error'
        })
    },
    sendEvent(path, addParams = []) {
      const commonParams = [
        { key: 'devicetype', value: 'mobile' },
        { key: 'pageType', value: 'home' }
      ]
      this.$addFrog(path, [...commonParams, ...addParams])
    }
  }
}
</script>
<style scoped lang="less">
.index-home {
  background: #f7f7f7;
}

.bottom-btn {
  margin: 60px auto;
}
</style>
