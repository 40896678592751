import WebTrack from '@yuanfudao/conan-web-track'
const defaultConfig = {
  project: 'zebra-ai-page',
  env: process.env.WINDOW_ENV === 'online' ? 'online' : 'test',
  performanceListener: true,
  pageName: 'others',
  session: {
    // 开启 session 功能
    enable: true
  },
  /** 哪些接口 url 需要 trace, 不配置或为空数组不开启， */
  allowUrlPatterns: []
}

const install = function (Vue, opitons = {}) {
  const webTrackSingle = new WebTrack({ ...defaultConfig, ...opitons })
  Vue.prototype.$webTrack = webTrackSingle
}

export default { install }
