<template>
  <div class="honor-desc">
    <p class="title">{{ honorInfo.title }}</p>
    <PPageTurning :listLen="honorList.length">
      <PNewsCard
        v-for="(honorItem, index) in honorList"
        :key="index"
        class="news-card"
        cardHeight="458px"
        imgHeight="270px"
        :title="honorItem.title"
        :desc="honorItem.content"
        :imgUrl="honorItem.imgUrl"
      />
    </PPageTurning>
  </div>
</template>
<script>
import PPageTurning from '../PPageTurning.vue'
import PNewsCard from '../PNewsCard.vue'

export default {
  components: {
    PPageTurning,
    PNewsCard
  },
  props: {
    honorInfo: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    honorList() {
      return this.honorInfo.honorList || []
    }
  }
}
</script>
<style scoped lang="less">
.title {
  margin-top: 128px;
  margin-bottom: 64px;
  font-weight: 600;
  font-size: 40px;
  line-height: 56px;
  text-align: center;
  color: #000;
}
.news-card + .news-card {
  margin-left: 24px;
}
</style>
