module.exports = {
  GALLERY_URL: '//ytkgallery.yuanfudao.biz',
  GALLERY_CDN: '//ytkgallery.yuanfudao.biz',
  BASE_URL: 'http://conan.yuanfudao.biz/h5/conan-web-page/',
  APE_WEB_PROXY_URL: '//ytk1.yuanfudao.biz/ape-web-proxy/api/wechat',
  YTK_HOST: '//ytk1.yuanfudao.biz',
  CONAN_HOST: '//conan.yuanfudao.biz',
  CONAN1_HOST: '//conan.yuanfudao.biz',
  CONAN2_HOST: '//conan.yuanfudao.biz',
  BMYY_WECHAT_NAME: 'bmyy', // 斑马英语服务号
  BMYY_WECHAT_APPID: 'wxbef5aa41ef7878a9',
  OSS_IMAGE_URL: '//conan-test.fbcontent.cn/conan-english',
  PROTOCOL: 'http:',
  ENGLISH_SYS: 20,
  ENGLISH_TRIAL: 112,
  MATH_TRIAL: 53,
  MATH_SYS: 37,
  CHINESE_TRIAL: 106,
  CHINESE_SYS: 105,
  PLANET_URL: '//ytkplanet.yuanfudao.biz',

  // ---- OSS 配置 -----
  OSS_BUCKET: 'static-nginx-test',
  OSS_MID_PATH: `h5/zebra-ai-page`
  // ---- OSS 配置 -----
}
