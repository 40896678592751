<template>
  <header class="header">
    <img
      class="logo"
      src="https://conan-online.fbcontent.cn/conan-oss-resource/AWY3UJFxNMbZlVFGcfiYC3BR1SmQiOffloru.png"
      alt="斑马"
      @click="jumpPage(navBarList[0])"
    />
    <div class="main">
      <a
        v-for="(nav, index) in navBarList"
        :key="index"
        @click="jumpPage(nav)"
        class="nav-item"
        :class="{ current: +current === index }"
        >{{ nav.title }}
      </a>
    </div>
    <div class="more">
      <div
        v-for="buttonItem in buttonList"
        :key="buttonItem.tag"
        class="btn"
        @click="jumpPage(buttonItem)"
      >
        {{ buttonItem.title }}
      </div>
    </div>
  </header>
</template>

<script>
import context from '../../context/index.js'
import tool from '@/utils/index'

export default {
  data() {
    return {
      currentPageName: '',
      navBarList: [
        { title: '首页', url: './index.html', tag: 'home' },
        { title: '斑马AI学', url: './banma.html', tag: 'zebra' },
        { title: '斑马百科', url: './baike.html', tag: 'encyclopedia' },
        { title: '斑马图书', url: './book.html', tag: 'book' },
        { title: '斑马玩教具', url: './toy.html', tag: 'goods' },
        { title: '小斑斑', url: 'https://www.xiaobanban.com?keyfrom=bm', tag: 'agebox' }
      ],
      buttonList: [
        {
          title: '下载中心',
          url: './download-center.html',
          tag: 'download'
        },
        {
          title: '技术支持',
          url: 'https://conan.yuanfudao.com/yun-webapp/conan-webapp-ai-service/page-customer-service/latest/customer-service.html?loginType=2&configId=da635d44b56ecf8e47ca6e2d30c206a3',
          tag: 'consult'
        }
      ]
    }
  },
  props: {
    current: {
      type: Number,
      default: 0
    }
  },
  mounted() {
    this.currentPageName = tool.getCurrentPageName()
  },
  methods: {
    jumpPage(item) {
      this.sendEvent(item.tag)
      if (item.tag === 'agebox') {
        window.open(item.url, '_blank')
      } else {
        location.href = item.url
      }
    },
    sendEvent(pageType) {
      const commonParams = [
        { key: 'devicetype', value: 'pc' },
        { key: 'subpage', value: pageType },
        { key: 'position', value: 1 },
        { key: 'pageType', value: context.navBarName[this.currentPageName] }
      ]
      this.$addFrog('/click/ZebraAIWebsite/subpage', commonParams)
    }
  }
}
</script>

<style scoped lang="less">
.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 80px;
  min-width: 1128px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  font-family: 'PingFang SC';
  background: #fff;
  box-sizing: border-box;
  .current {
    color: #00cc66;
  }
  a {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
  }
}
.main {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo {
  height: 40px;
  width: 265px;
}
.nav-item + .nav-item {
  margin-left: 32px;
}
.more {
  display: flex;
  align-content: center;
}
.btn {
  width: 82px;
  line-height: 32px;
  margin-right: 20px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 32px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
  cursor: pointer;
}
</style>
